import { createRouter, createWebHistory } from 'vue-router';
import Task1 from '../components/pages/Task-1-page.vue';
import Task2 from '../components/pages/Task-2-page.vue';
import Home from '../components/pages/Home-page.vue';

const routes = [
  {
    path: '/task1',
    component: Task1,
    name: 'task-1-page',
    meta: { title: 'Task 1' },
  },
  {
    path: '/task2',
    component: Task2,
    name: 'task-2-page',
    meta: { title: 'Task 2' },
    children: [
      {
        path: 'convert',
        name: 'Convert',
        meta: { title: 'Convert' },
        component: () => import('../components/elements/ConvertTab.vue'),
      },
      {
        path: 'test-data',
        name: 'TestData',
        meta: { title: 'Test data' },
        component: () => import('../components/elements/TestDataTab.vue'),
      },
      {
        path: '',
        redirect: '/task2/convert',
      },
    ],
  },
  {
    path: '/home',
    component: Home,
    name: 'home-page',
    meta: { title: 'PDP' },
  },
  { path: '/', redirect: '/home' },
  { path: '/task2', redirect: '/task2/convert' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
