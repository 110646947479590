<template>
  <div>
    <h1>Send Email</h1>

    <v-alert
      v-if="successAlert"
      type="success"
      dismissible
      closable
      :title="successAlertTitle"
      :text="successAlertText"
      data-cy="successAlert"
    ></v-alert>

    <v-alert
      v-if="errorAlert"
      type="error"
      dismissible
      closable
      :title="errorAlertTitle"
      :text="errorAlertText"
      data-cy="errorAlert"
    ></v-alert>

    <form @submit.prevent="sendEmail" novalidate>
      <div class="form-group">
        <label for="name">Name:</label>
        <input
          v-model="form.name"
          type="text"
          id="name"
          name="name"
          data-cy="nameField"
          placeholder="Enter your name"
        />
      </div>

      <div class="form-group">
        <label for="email">Recipient Email:</label>
        <input
          v-model="form.email"
          type="email"
          id="email"
          name="email"
          data-cy="emailField"
          placeholder="Enter recipient email"
        />
      </div>

      <div class="form-group">
        <label for="message">Message Body:</label>
        <textarea
          v-model="form.message"
          id="message"
          name="message"
          data-cy="messageTextarea"
          placeholder="Enter your message"
          rows="5"
        ></textarea>
      </div>

      <div class="radio-group">
        <label>
          <input
            v-model="form.method"
            name="method"
            type="radio"
            id="smtp"
            value="SMTP"
            data-cy="smtp"
          />
          SMTP
        </label>
        <label>
          <input
            v-model="form.method"
            name="method"
            type="radio"
            id="sendGrid"
            value="SEND_GRID"
            data-cy="sendgrid"
          />
          SendGrid
        </label>
      </div>

      <button type="submit" class="btn" data-cy="sendButton">Send Email</button>
    </form>
  </div>
</template>

<script>
import axios from '@/plugins/axios';

export default {
  data() {
    return {
      reCaptchaToken: null,
      alertTitle: '',
      alertText: '',
      alertType: '',
      form: {
        name: '',
        email: '',
        message: '',
        method: 'SMTP',
      },
      errorAlert: false,
      errorAlertTitle: '',
      errorAlertText: '',
      successAlert: false,
      successAlertTitle: '',
      successAlertText: '',
    };
  },
  async mounted() {
    this.updateRecaptchaToken();
  },
  methods: {
    async sendEmail() {
      try {
        this.successAlert = false;
        this.errorAlert = false;
        const headers = {
          'x-recaptcha-token': this.reCaptchaToken,
        };

        await axios.post('/api/task1/send', this.form, { headers });
        this.successAlertTitle = 'Success';
        this.successAlertText = 'Email sent successfully!';
        this.successAlert = true;
        this.resetForm();
      } catch (error) {
        this.handleError(error);
      }
    },
    updateRecaptchaToken() {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
              action: 'sendEmail',
            })
            .then((token) => {
              this.reCaptchaToken = token;
            })
            .catch((error) => {
              console.error('reCAPTCHA token error:', error);
            });
        });
      } else {
        console.error('reCAPTCHA is not loaded');
      }
    },
    resetForm() {
      this.form = {
        name: '',
        email: '',
        message: '',
        method: 'SMTP',
      };
      this.alert = false;
      this.updateRecaptchaToken();
    },
    handleError(error) {
      console.error('Send email error:', error);
      this.errorAlert = true;
      this.errorAlertTitle =
        error.response.data?.statusCode === 403
          ? 'Not valid reCaptcha'
          : 'Validation error';
      this.errorAlertText = Array.isArray(error.response.data?.message)
        ? error.response.data.message.join('; ')
        : error.response.data?.message || 'Something went wrong';
      this.updateRecaptchaToken();
    },
  },
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.radio-group {
  display: flex;
  gap: 15px;
}

.btn {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.btn:hover {
  background-color: #0056b3;
}
</style>
