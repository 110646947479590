<template>
  <div>
    <h1>PDP</h1>
    <p>Backend_Andrii_H</p>
  </div>
</template>

<script>
export default {
  //
};
</script>

<style scoped></style>
