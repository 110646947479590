import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as colors from 'vuetify/util/colors';

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.grey.darken1,
          secondary: colors.grey.lighten4,
        },
      },
    },
  },
});

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.mount('#app');
