<template>
  <v-card flat class="d-flex flex-column fill-height">
    <v-tabs v-model="tab" bg-color="primary" @change="onTabChange">
      <v-tab :to="{ name: 'Convert' }" value="convert">Convert</v-tab>
      <v-tab :to="{ name: 'TestData' }" value="test-data">Test data</v-tab>
    </v-tabs>

    <v-card-text class="flex-grow-1">
      <router-view></router-view>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: this.$route.name.toLowerCase() || 'convert',
    };
  },
  watch: {
    $route(to) {
      this.tab = to.name.toLowerCase();
    },
  },
  methods: {
    onTabChange(value) {
      this.$router.push({ name: value.replace('-', '') });
    },
  },
};
</script>

<style>
.v-card {
  min-height: 100%;
  width: 100%;
}

.v-card-text {
  overflow-y: auto;
  flex-grow: 1;
}
</style>
