<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>PDP</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-navigation-drawer app permanent clipped>
            <v-list dense>
              <v-list-item-group>
                <v-list-item to="/home" exact>
                  <v-list-item-content>
                    <v-list-item-title>Home</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/task1">
                  <v-list-item-content>
                    <v-list-item-title>Task 1</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/task2">
                  <v-list-item-content>
                    <v-list-item-title>Task 2</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>

          <v-col cols="10" class="content-area">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'PDP';
      },
    },
  },
};
</script>

<style scoped>
.v-app-bar {
  background-color: #6200ea;
  color: white;
}

.v-navigation-drawer {
  border-right: 1px solid #ccc;
  background-color: #f5f5f5;
}

.v-list-item-title {
  font-weight: 500;
  color: #424242;
}

.v-main {
  padding-top: 64px;
}

.content-area {
  padding: 20px;
}
</style>
